@import url(https://fonts.googleapis.com/css?family=Cutive+Mono);
:root {
  --color-black: #222;
  --color-white: #fff;
  --color-accent: #f43;
  --color-background: black;
  --color-textcolor: #39f10e;
  --color-textcolor-dark: #1b8502;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: var(--font-base-size);
  line-height: var(--font-base-line-height);
}

body {
  -webkit-font-smoothing: antialiased;
  background: black;
  background: var(--color-background);
  color: #39f10e;
  color: var(--color-textcolor);
  margin: 0;
  font-family: Cutive Mono;
  text-shadow: 0 0 4px #39f10e;
  text-shadow: 0 0 4px var(--color-textcolor);
}

* {
  box-sizing: border-box;
}

html,
body,
body > div,
body > div > div {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

