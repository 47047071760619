@import url("https://fonts.googleapis.com/css?family=Cutive+Mono");

:root {
  --color-black: #222;
  --color-white: #fff;
  --color-accent: #f43;
  --color-background: black;
  --color-textcolor: #39f10e;
  --color-textcolor-dark: #1b8502;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: var(--font-base-size);
  line-height: var(--font-base-line-height);
}

body {
  -webkit-font-smoothing: antialiased;
  background: var(--color-background);
  color: var(--color-textcolor);
  margin: 0;
  font-family: Cutive Mono;
  text-shadow: 0 0 4px var(--color-textcolor);
}

* {
  box-sizing: border-box;
}

html,
body,
body > div,
body > div > div {
  height: 100%;
}
